// Import ECase main library
@use '@angular/material' as mat;
//@import "app/core/scss/core";
@import "~synto-common/core";
@import "~flag-icons/css/flag-icons.min.css";

$offset: 187;
$duration: 1.4s;

/*FUSE Splash Screen CSS*/
#eCase-splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2D323D;
  z-index: 99999;
  pointer-events: none;
}

#eCase-splash-screen .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

#eCase-splash-screen .logo {
  width: 128px;
  margin: 0 auto;
}

#eCase-splash-screen .logo img {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
}
/*/ FUSE Splash Screen CSS*/

.spinner {
  animation: rotator $duration linear infinite;
}

// Spinner Style starts
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: #80808021 !important;
}
.mat-input-element {
  line-height: 16px !important;
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: black !important;
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
  colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: mat.get-color-from-palette($accent);
  }
  25% {
    stroke: mat.get-color-from-palette($accent);
  }
  50% {
    stroke: mat.get-color-from-palette($accent);
  }
  75% {
    stroke: mat.get-color-from-palette($accent);
  }
  100% {
    stroke: mat.get-color-from-palette($accent);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

// Spinner Style ends
/* Progress Bar style starts*/
.progress {
  position: absolute;
  height: 5px;
  display: block;
  width: 100%;
  background-color: #dbb6b8;
  border-radius: 2px;
  background-clip: padding-box;
  overflow: hidden;
}

.progress .determinate {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: mat.get-color-from-palette($accent);
  transition: width .3s linear;
}

.progress .indeterminate {
  background-color: mat.get-color-from-palette($accent);
}

.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

#warning-card {
  background-color: #ebeaea;
  margin-bottom: 20px;
}
#warning-card .warning-icon-div{
  width: 28px;
  border: solid 2px #828286;
  height: 28px;
  border-radius: 15px;
  float: left;
  margin: -4px 10px 0 0;
}

#warning-card .mat-icon{
  color: #98012f;
  margin: 0 10px 0 0;

}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@font-face {
  font-display: auto;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v97/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v74/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.mat-chip.mat-standard-chip {
  border-radius: 5px;
  background-color: #efecec;
  border: solid thin;
  border-color: #d4d2d2;
}


.NumberIfTabFocused {
  .mat-badge-content {
    background-color: red !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fb_span_li{
  margin-left: 25px;
  display: block;
}

