@import "../../../../../src/lib/core/scss/eCase.scss";


.tableFormFieldError {
  font-weight: 700;
  color: #c62828;
}


tooltipbody {

  > mat-sidenav-container {
    height: 100%;
  }
}

.angular-table{
  white-space: normal !important;
  //overflow-x: auto !important;
}


// class used to controle margins of forms inside a page, for example check app/admin/admin-tools/translation-manager/translatiotooltipn-manager.component.html

.ecase-form-container {
  margin: 20px 15px 5%;
}

.ecase-input-full-width {
  width: 100%;
}

.ecase-table-full-width {
  width: 100%;
  margin-top: 15px;
}

//.nav-link-container {
//  &.selected {
//    //color: #EF6C00;
//    //border-bottom: 3px #EF6C00 solid;
//    text-decoration: none;
//    color: red;
//    border-bottom: 3px red solid;
//  }
//  &.onHover:hover{
//    text-decoration: none !important;
//  }
//}



// See Usage example in ECaseSnackBarService class
.ecase-snack-bar-success {
  background-color: #F4FDEF;
  color: green;
  border: 1px solid #3F9305;
}

// See Usage example in ECaseSnackBarService class
.ecase-snack-bar-failure {
  background-color: #F4FDEF;
  color: red;
  border: 1px solid #9e1317;
}

// See Usage example in ECaseSnackBarService class
.ecase-snack-bar-warning {
  background-color: yellow;
  color: #0f0f10;
  border: 1px solid #8d9e13;
}

.angular-table {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  position: relative;
  border: 1px solid rgba(0, 0, 0, .12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff;
}

.angular-table-thead {
  padding-bottom: 3px;
}

.angular-table-th {
  padding: 0 18px 12px 18px;
  position: relative;
  vertical-align: bottom;
  //text-overflow: ellipsis;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, .54);
  padding-bottom: 8px;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid rgba(0,0,0,.12);
}



.angular-table-th > p {
  margin: 0 !important;
}

/*
.mat-sort-header-container > button {
  width: 100%;
  justify-content: center;
}
*/




.angular-table-tr {
  position: relative;
  height: 48px;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-property: background-color;
}

.angular-table-td {
  position: relative;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  padding: 12px 18px;
  box-sizing: border-box;
  vertical-align: middle;
  padding-left: 24px;
}

.scheduler-table-td {
  position: relative;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
}

.angular-input-white-underline {
  ::ng-deep .mat-form-field-underline  {
    color: white !important;;
  }
  ::ng-deep .mat-form-field-appearance-outline {
    color: white !important;;
  }
  ::ng-deep .mat-form-field-outline {
    color: white !important;;
  }
}
.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    margin-top: 6px;
    padding: 0.6em 0;
    border-top: none;
  }
}
.counter-style {
  color: #595959;
  float: right;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.formLabel {
  font-size: 14px !important;
}

::ng-deep .mat-radio-label-content {
  font-size: 14px !important;
}

::ng-deep .mat-checkbox-label {
  font-size: 14px !important;
}

::ng-deep .mat-checkbox {
  font-size: 14px !important;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}
