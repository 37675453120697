
.tooltip {
  height: auto !important;
  background-color: rgba(97, 97, 97,1);
  color: #FFF;
  width: 500px;
  max-width: 500px !important;
  border-radius: 2px;
  padding: 12px;
  word-break: break-all;
  white-space: pre-line !important;
}
