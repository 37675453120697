button,
input[type=email],
input[type=tel],
input[type=text],
input[type=password],
input[type=image],
input[type=submit],
input[type=button],
input[type=search],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}

// Use these styles to fields inside the form tag
// Usage example add-new-form-fb-button-component-dialog.component.html
.eCase-forms {
  display: block !important;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}
// Use these styles to fields with validation error message inside the form tag
// Usage example add-new-form-fb-button-component-dialog.component.html
.eCase-forms-validation-error {
  color: #f44336;
  margin-top: -2%;
  margin-bottom: 10px;
  font-size: 85%;
}

.mat-form-field{
  width: 100%;
  margin-top: 1%;
}
