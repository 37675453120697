// Dialog Action Buttons

// change this variable to flex-start to align buttons left
// center for aligning in centre and flex-end for aligning to right
//  for example check app\core\components\questionsdialog\questionsdialog.component.html
$justification: flex-start;

.eCase-dialog-action-button-align {
  display: flex;
  justify-content: $justification;
}

.eCase-dialog-positive-action-button {
  background-color: #0A9AE4;
  color: white;
  margin-right: 20px !important;
}

.eCase-dialog-negative-action-button {
  background-color: #F44539;
  color: white;
  // margin-left: 20px !important;
}

// class that will assign the red color to all back buttons
.backBtn{
  background-color: red;
  color: #fff;
}

.mat-icon-button.eCase-in-table-mat-button {
  padding: 5px 5px 5px 0;
  width: 80px;
  height: 35px;
  flex-shrink: 0;
  line-height: 35px;
  border-radius: 5px;
  vertical-align: middle;
  box-shadow:
    0px -1px 0.5px rgba(0, 0, 0, 0.04),
    -2px 2px 5.3px rgba(0, 0, 0, 0.1),
    0px 3px 8px rgba(0, 0, 0, 0.07)
}

.mat-icon-button.eCase-in-table-mat-button span {
  float: right;
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  line-height: 24px;
  color: #707070;
}
