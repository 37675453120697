.mat-row-height{
  cursor: pointer;
  min-height: 55px;
}

.wrap-table-text {
  word-break: break-word;
  padding: 10px !important;
  text-align: center;
}


/* Styles for Responsive Tables */

.responsive-table-style {

  @media screen and (max-width: 960px) {

    .mat-table .mat-row {
      border-bottom: 5px solid #ddd;
      display: block;
      height: auto !important;
    }

    .mat-table .mat-header-cell {

      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .mat-cell {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 1em;
      text-align: right !important;
      // height: 30px;
      // margin-bottom: 4%;
      //padding-left: 0px !important;
      min-height: auto;
      // overflow: unset;
      padding: 17px !important;
    }
    .mat-cell:before {

      content: attr(title);
      float: left;
      font-weight: normal;

      font-size: .90em;
    }

    .mat-table .mat-cell:last-child {
      border-bottom: 0;
      padding-right: 0px !important;
    }
    .mat-table .mat-cell:first-child {
      // margin-top: 4%;
      //display: none;
    }

  }
}

table {
  width: 100%;
}

mat-header-row{
  height: 50px !important;
}

mat-header-cell,mat-cell {
  justify-content: center;
}


.searchFields{
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  height: 30px;
  width: 65%;
  padding-left: 5px !important;
}

.mat-sort-header-container > button{
  //padding-left: 15%;

}
.mat-sort-header-container {
  //padding-left: 30px;
  //text-align: center !important;
  display: inline-flex !important;

}
.tr.mat-header-row{
  height: 65px !important;

}

$default-color: #444;

.form-item {
  &:not(:first-child) {
    margin-top: 1em;
  }

  > :not(:first-child) {
    margin-top: 0.25em;
  }
  width: 100%;
}

.form-item__label {
  display: block;
}

.select_filter {
  display: inline-block;
  padding: 0.5em;
  vertical-align: middle;
  background: #fff;
  border-radius: 4px;
  border: 1px solid lightgray;
  width: 70%;
  height: 30px;
}




.filter-items__element--select {
  -webkit-appearance: none;
  padding-right: 2em;
  padding-left: 5px !important;

  // The select element is set to required
  // as long as the selected options value
  // is empty the element is not valid.
  &:invalid {
    // color: #575757;
    color: gray;
    font-size: 12px;
    font-weight: normal;
    //padding-left: 8px !important;

  }

  // Styling for browsers which do support
  // styling select option elements directly
  [disabled] {
    color: gray;
  }

  option {
    color: $default-color;


  }
}

// Quick and dirty ugly caret replacement.
.select-wrap {
  &::after {
    margin-left: -2em;
    font-size: 0.75em;
    pointer-events: none;
    content: '▼';
  }
  width: 100%;
  text-align: center;
}
.select-wrap-for-FirstFilter {
  &::after {
    margin-left: -2em;
    font-size: 0.75em;
    pointer-events: none;
    content: '▼';
  }
}

.info {
  max-width: 42em;

  p {
    line-height: 1.5;
  }
}


* {
  box-sizing: border-box;
}

.filter_row_first_cell {
  cursor: pointer;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

.dropbtn {
  background-color: #515151;
  margin-right: 15px;
  color: white;
  border: none;
}

.dropdown {
  float: right;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position:absolute;
  min-width: 90px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/////

.dropup {
  float: right;
  display: inline-block;
}
.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.dropup-content {
  display: none;
  position:absolute;
  min-width: 90px;
  margin-top: -105px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

//////

.mat-header-cell{
  font-weight: 400;
}
.dropbtn a{
  overflow-x: hidden !important;
}
.dropup-content a:hover {background-color: #ddd;overflow-x: hidden !important;  }

.dropup:hover .dropup-content {display: block; overflow-x: hidden !important; }

.dropdown-content a:hover {background-color: #ddd;overflow-x: hidden !important;}

.dropdown:hover .dropdown-content {display: block; overflow-x: hidden !important;}

.filters{
  width: 12%;
  margin-right: 8%;
  cursor: pointer;
}

.mat-sort-header-sorted{
  font-weight: bold !important;
}

.clearFilters{
  width: 12%;
  margin-right: 8%;
  cursor: context-menu;;
}

.mat-table_filtered_header_cell{
  background-color: #F6F6F6;
}

.row_filter_icon{
  width: 22px !important;
  margin-right: 2% !important;

  //margin-right: 2% !important;
  //margin-left: 10px !important;
  cursor: pointer !important;
  margin-bottom: -7px !important;
}

mat-row{
  position: relative;
  //  height: 84px;
}

a{
  cursor: pointer;
}

.ecase-mat-table{
  flex: 1 1 auto;
  margin-top: 40px;
}

.ecase-mat-card {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  @media screen  and (max-width: 580px) {
    margin-top: 4%;
  }
}


/* Hack to deal with mat-ripple breaking css */
td:last-child, th:last-child{
  padding-right: 0px !important;
}
td:last-of-type, th:last-of-type {
  padding-right: 0px !important;
}




.item-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

tr.mat-header-row {
  height: 60px;
}

td {
  //word-break:break-all;
  text-align: center !important;
 // padding-left: 1px!important;
}
th {
  text-align: center !important;
  padding-left: 5px !important;
}

.table-header-filter-width {
  //width: 50%;
}

/*class for th accessibility */
.nodisplay {
  color: transparent;
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}


//table { // this somehow makes it work for td
//  table-layout:auto;
//  //width:100%;
//}

//.mat-cell{
//  flex: 1;
//  //display: flex;
//  align-items: center;
//  overflow: hidden;
//  word-wrap: break-word;
//  min-height: inherit;
//}

