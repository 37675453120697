@font-face {
  font-display: auto;
  font-family: 'meteocons';
  src: url('fonts/meteocons.ttf?1o770y') format('truetype'),
  url('fonts/meteocons.woff?1o770y') format('woff'),
  url('fonts/meteocons.svg?1o770y#meteocons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.meteocons {
  font-family: 'meteocons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sunrise:before {
  content: "\e900";
}

.icon-sun:before {
  content: "\e901";
}

.icon-moon:before {
  content: "\e902";
}

.icon-sun2:before {
  content: "\e903";
}

.icon-windy:before {
  content: "\e904";
}

.icon-wind:before {
  content: "\e905";
}

.icon-snowflake:before {
  content: "\e906";
}

.icon-cloudy:before {
  content: "\e907";
}

.icon-cloud:before {
  content: "\e908";
}

.icon-weather:before {
  content: "\e909";
}

.icon-weather2:before {
  content: "\e90a";
}

.icon-weather3:before {
  content: "\e90b";
}

.icon-lines:before {
  content: "\e90c";
}

.icon-cloud2:before {
  content: "\e90d";
}

.icon-lightning:before {
  content: "\e90e";
}

.icon-lightning2:before {
  content: "\e90f";
}

.icon-rainy:before {
  content: "\e910";
}

.icon-rainy2:before {
  content: "\e911";
}

.icon-windy2:before {
  content: "\e912";
}

.icon-windy3:before {
  content: "\e913";
}

.icon-snowy:before {
  content: "\e914";
}

.icon-snowy2:before {
  content: "\e915";
}

.icon-snowy3:before {
  content: "\e916";
}

.icon-weather4:before {
  content: "\e917";
}

.icon-cloudy2:before {
  content: "\e918";
}

.icon-cloud3:before {
  content: "\e919";
}

.icon-lightning3:before {
  content: "\e91a";
}

.icon-sun3:before {
  content: "\e91b";
}

.icon-moon2:before {
  content: "\e91c";
}

.icon-cloudy3:before {
  content: "\e91d";
}

.icon-cloud4:before {
  content: "\e91e";
}

.icon-cloud5:before {
  content: "\e91f";
}

.icon-lightning4:before {
  content: "\e920";
}

.icon-rainy3:before {
  content: "\e921";
}

.icon-rainy4:before {
  content: "\e922";
}

.icon-windy4:before {
  content: "\e923";
}

.icon-windy5:before {
  content: "\e924";
}

.icon-snowy4:before {
  content: "\e925";
}

.icon-snowy5:before {
  content: "\e926";
}

.icon-weather5:before {
  content: "\e927";
}

.icon-cloudy4:before {
  content: "\e928";
}

.icon-lightning5:before {
  content: "\e929";
}

.icon-thermometer:before {
  content: "\e92a";
}

.icon-compass:before {
  content: "\e92b";
}

.icon-none:before {
  content: "\e92c";
}

.icon-celsius:before {
  content: "\e92d";
}

.icon-fahrenheit:before {
  content: "\e92e";
}
